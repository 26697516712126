import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import translationEnglish from "./translations/english/translation.json";
import translationSlovak from "./translations/slovak/translation.json";

const resources = {
    en: {
        translation: translationEnglish,
    },
    sk: {
        translation: translationSlovak,
    },
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", //default language
    })

export default i18next;