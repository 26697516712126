export const pwcCoordinates: [number, number] = [
  48.145138182856435, 17.124222834082413,
];
export const SUB_ZERO = -273.14;
export const IMAGE_BOUNDS_BRATISLAVA: [[number, number], [number, number]] = [
  [48.10043370394876, 16.934455027634724],
  [48.22517579693178, 17.226333467293352],
]; // wrong projection "fixed" manually, original - [[48.09943370394876, 16.935455027634724], [48.22517579693178, 17.227333467293352]];
export const BRATISLAVA_CENTER: [number, number] = [
  48.16172794000261, 17.082228931691088,
];
