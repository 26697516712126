import { MapImageOverlay } from "../Leaflet/MapImageOverlay";

import NYImg from "../../data/minigame_images/0_central_park.webp";
import KippenheimImg from "../../data/minigame_images/1_kippenheim_retry.webp";
import TokyoImg from "../../data/minigame_images/2_tokyo.webp";
import AmazonImg from "../../data/minigame_images/3_amazon.webp";
import Aquapark from "../../data/minigame_images/4_aquapark.webp";

const IMAGE_BOUNDS_NY: [[number, number], [number, number]] = [
  [40.41004415390688, -74.28882029919762],
  [40.93681868619464, -73.57790117127229],
];
const IMAGE_BOUNDS_KIPPENHEIM: [[number, number], [number, number]] = [
  [48.082048660836534, 7.494024844367022],
  [48.37580771961734, 8.029445159209356],
];
const IMAGE_BOUNDS_TOKYO: [[number, number], [number, number]] = [
  [35.440211998465756, 139.5361024713921],
  [35.78541293190855, 139.99938978752908],
];
const IMAGE_BOUNDS_AMAZON: [[number, number], [number, number]] = [
  [-0.9226305816479936, -60.22288333898201],
  [-0.6571768083773151, -59.81313811900736],
];
const IMAGE_BOUNDS_AQUAPARK: [[number, number], [number, number]] = [
  [51.86660648986651, 13.42938178287342],
  [52.10840745082572, 13.848794472332138],
];

const imageOverlayArray: MapImageOverlay[] = [
  { imageURL: NYImg, opacity: 1, bounds: IMAGE_BOUNDS_NY },
  { imageURL: KippenheimImg, opacity: 1, bounds: IMAGE_BOUNDS_KIPPENHEIM },
  { imageURL: TokyoImg, opacity: 1, bounds: IMAGE_BOUNDS_TOKYO },
  { imageURL: AmazonImg, opacity: 1, bounds: IMAGE_BOUNDS_AMAZON },
  { imageURL: Aquapark, opacity: 1, bounds: IMAGE_BOUNDS_AQUAPARK },
];

export default imageOverlayArray;
