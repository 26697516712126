import logo from "./data/logo.png";
import introMap from "./data/ba_sat_base_sm.jpeg";
import introVideo from "./data/landsat_videos/locally_normalized_temps_landsat.webm";
import "./AppNG.scss";
const VideoLeaflet = React.lazy(() => import("./components/Leaflet/VideoLeaflet"));
import globalNormedS3 from "./data/sentinel_videos/globally_normalized_temps_sentinel_sm.mp4";
import globalNormedS3Captions from "./data/sentinel_videos/globally_normalized_temps_sentinel.json";
import localNormedS3 from "./data/sentinel_videos/locally_normalized_temps_sentinel_sm.mp4";
import localNormedS3Captions from "./data/sentinel_videos/locally_normalized_temps_sentinel.json";
import localNormedS3MinMax from "./data/sentinel_videos/locally_normalized_temps_sentinel_temps.json";
import localNormedL8 from "./data/landsat_videos/locally_normalized_temps_landsat_sm.mp4";
import localNormedL8Captions from "./data/landsat_videos/locally_normalized_temps_landsat.json";
import localNormedL8MinMax from "./data/landsat_videos/locally_normalized_temps_landsat_temps.json";
import hetspot_bwr_img from "./data/heatspot_filtering_data/heatspot_score_bwr.webp";

import { SUB_ZERO, BRATISLAVA_CENTER, IMAGE_BOUNDS_BRATISLAVA } from "./utils/constants";
import React, { Suspense, useEffect, useState } from "react";

import Simg from "./data/example_images_for_zooming/example_sentinel.webp";
import Limg from "./data/example_images_for_zooming/example_landsat.webp";
import imgL from "./data/heatspot_filtering_data/mean_temp_plasma.webp";
import HeatScoreImg from "./data/heatspot_filtering_data/heatspot_score_plasma.webp";
import * as d3 from "d3";
import { DSVRowString } from "d3";

const TemperatureCutoff = React.lazy(() => import("./components/TemperatureCutoff/TemperatureCutoff"));
const SpotInfo = React.lazy(() => import("./components/SpotInfo/SpotInfo"));

const Magnifierv2 = React.lazy(() => import("./components/Magnifier/GlassMagnifier"));
const ImgSplitPane = React.lazy(() => import("./components/SplitPane/SplitPane"));
import { ArrowRightIcon, ArrowLeftIcon, ArrowDownIcon, CursorArrowRippleIcon } from '@heroicons/react/24/solid';
import infrastructure from './assets/img/infrastructure.svg';
import agriculture from './assets/img/agriculture.svg';
import community from './assets/img/community.svg';
const RidgelineControls = React.lazy(() => import("./components/Graph/Ridgeline/RidgeLineControls"));
const ViolinPlotContainer = React.lazy(() => import("./components/Graph/Violin/ViolinPlotContainer"));
const Scatterplot = React.lazy(() => import("./components/Graph/Scatter/Scatter"));
import NDVIimg from "./data/splitPane/ndvi_grid_greens.webp";
const ReadMore = React.lazy(() => import("./components/ReadMore/ReadMore"));
const ImpactSlider = React.lazy(() => import("./components/ImpactSlider/ImpactSlider"));
const BarPlotTempQuantilesContainer = React.lazy(() => import("./components/Graph/Bars/BarPlotTempQuantilesContainer"));
const DistrictInfo = React.lazy(() => import("./components/DistrictInfo/DisctrictInfo"));
import TempQuantiles from "./data/district_vis_data/temp_quantiles_coolwarm.png";
const Minigame = React.lazy(() => import("./components/Minigame/Minigame"));
import imageOverlayArray from "./components/Minigame/ImageLoader";
const Footer = React.lazy(() => import("./components/Footer/Footer"));
import Loading from "./components/Loading/Loading";
import { useTranslation } from "react-i18next";
import { MultiValue, SingleValue } from "react-select";
import Select from 'react-select';
export interface FormattedDataEntry {
  key: string;
  values: number[];
}

export interface LandUsesOptions {
  value: string;
  label: string;
}

export interface FormattedDataEntry {
  key: string;
  values: number[];
}

export interface SeasonOption {
  value: string;
  label: string;
}

interface TemperatureCategory {
  label: string;
  value: string;
};

const App = () => {
  const { t, i18n } = useTranslation();
  const overlays = [globalNormedS3, localNormedS3];
  const overlay_captions = [globalNormedS3Captions, localNormedS3Captions];
  const overlay_minmax = [
    { Minimum: [-5 - SUB_ZERO], Maximum: [35 - SUB_ZERO] },
    localNormedS3MinMax,
  ];
  const [selectedOverlay, setSelectedOverlay] = useState<number>(0);
  const [formattedData, setFormattedData] = useState<FormattedDataEntry[]>([]);
  const [parsecsv, setParseCsv] = useState<{ x: number; y: number }[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<SingleValue<SeasonOption>>({ label: t('summer'), value: "summer" });
  const [selectedLandUses, setSelectedLandUses] = useState<MultiValue<LandUsesOptions>>([
    { label: t('grass'), value: "grass" },
    { label: t('water'), value: "water" },
    { label: t('parking'), value: "parking" },
    { label: t('forest'), value: "forest" },
    { label: t('residential'), value: "residential" },
    { label: t('retail'), value: "retail" },
  ]);
  const [layout, setLayout] = useState<Partial<Plotly.Layout>>({});
  const [firstSelection, setFirstSelection] = useState<SingleValue<SeasonOption>>({ label: t("vineyard"), value: "vineyard" });
  const [secondSelection, setSecondSelection] = useState<SeasonOption | null>({ label: t("parking"), value: "parking" });
  const [violinLayout, setViolinLayout] = useState<Partial<Plotly.Layout>>({});
  const colors = ["#D04A02", "#FFB600"];
  const [temperatureSelection, setTemperatureSelection] = useState<SingleValue<TemperatureCategory>>({ label: t("Very warm"), value: "Very warm" });
  const languageOptions = [
    { value: 'en', label: t('en') },
    { value: 'sk', label: t('sk') },
  ];
  const [languageSelect, setLanguageSelect] = useState<{ value: string, label: string }>({ value: 'en', label: t('en') });
  const [animate, setAnimate] = useState<boolean>(false);

  useEffect(() => {
    d3.csv(`${process.env.PUBLIC_URL}/data/lu_data.csv`).then((res) => {
      const newData: { [key: string]: number[] } = {};

      res.forEach((row: DSVRowString<string>) => {
        const landuse = row["Landuse"];
        const summerTemp = parseFloat(row["Summer_temp"] || "0") + SUB_ZERO;
        const autumnTemp = parseFloat(row["Autumn_temp"] || "0") + SUB_ZERO;
        const winterTemp = parseFloat(row["Winter_temp"] || "0") + SUB_ZERO;
        const springTemp = parseFloat(row["Spring_temp"] || "0") + SUB_ZERO;

        if (landuse !== "-1") {
          if (!newData.hasOwnProperty(`${landuse}_Autumn_temp`)) {
            newData[`${landuse}_Autumn_temp`] = [];
          }
          newData[`${landuse}_Autumn_temp`].push(autumnTemp);

          if (!newData.hasOwnProperty(`${landuse}_Winter_temp`)) {
            newData[`${landuse}_Winter_temp`] = [];
          }
          newData[`${landuse}_Winter_temp`].push(winterTemp);

          if (!newData.hasOwnProperty(`${landuse}_Summer_temp`)) {
            newData[`${landuse}_Summer_temp`] = [];
          }
          newData[`${landuse}_Summer_temp`].push(summerTemp);

          if (!newData.hasOwnProperty(`${landuse}_Spring_temp`)) {
            newData[`${landuse}_Spring_temp`] = [];
          }
          newData[`${landuse}_Spring_temp`].push(springTemp);
        }
      });

      const formattedData: FormattedDataEntry[] = Object.entries(newData).map(([key, values]) => ({
        key,
        values,
      }));
      setFormattedData(formattedData);
    });
  }, []);

  let availableLandUses = Array.from(new Set(formattedData.map((entry) => entry.key.split("_")[0])));

  let filteredData = formattedData.filter(
    (entry) => entry.key.includes(selectedSeason?.value || "") && (selectedLandUses.length === 0 || selectedLandUses.some((lu) => entry.key.includes(lu.value)))
  );

  useEffect(() => {
    d3.csv(`${process.env.PUBLIC_URL}/data/ndvi_graph.csv`).then((res) => {
      const parsedData = res
        .map((d) => {
          if (d.Temp && d.Non_green_surface_share) {
            const tempCelsius = +d.Temp + SUB_ZERO;
            return {
              x: tempCelsius,
              y: +d.Non_green_surface_share,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean) as { x: number; y: number }[];
      setParseCsv(parsedData);
    });
  }, []);

  const switchSelectedOverlay = (): void => {
    let overlay = document.getElementById("overlay-extra-text");
    let placeholder = document.getElementById("overlay-extra-placeholder");
    if (placeholder?.style.display === 'none') {
      placeholder.style.display = 'flex';
    }

    if (overlay?.style.opacity === '0') {
      overlay.style.opacity = '1';
    }

    if (selectedOverlay === 0) {
      setSelectedOverlay(1);
    } else {
      setSelectedOverlay(0);
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const formatName = (name: string): string => {
    const usage = name.split("_")[0];
    return capitalizeFirstLetter(t(usage));
  };

  const onClickLanguageChange = (e: { value: string, label: string } | null) => {
    const language = e?.value;
    i18n.changeLanguage(language);

    if (language) {
      setLanguageSelect({ value: language, label: t(language) })
    }

    if (selectedSeason) {
      setSelectedSeason({ label: t(selectedSeason.value), value: selectedSeason.value });
    }

    if (selectedLandUses) {
      let translated = [];
      for (const landUse of selectedLandUses) {
        translated.push({ label: t(landUse.value), value: landUse.value });
      }
      setSelectedLandUses(translated);
    }

    const global_minimum: number = filteredData.reduce((prev, current) => {
      const local_minimum: number = Math.min(...current.values);
      return Math.min(prev, local_minimum);
    }, Number.MAX_VALUE);

    setLayout({
      annotations: filteredData.map(({ key, values }, index) => ({
        width: 100,
        text: `${formatName(key)}`,
        borderpad: 3,
        showarrow: false,
        align: "center",
        y: index + 0.35,
        x: global_minimum - 1.5,
        bordercolor: "#2d2d2dEE",
        bgcolor: "#2d2d2dCC",
        font: {
          color: "white",
          size: 16,
          family: "Helvetica",
        },
      })),
      // width: width / 1.4,
      height: 500,
      xaxis: {
        ticksuffix: "°C",
        showgrid: false,
        titlefont: { size: 24 },
        showticklabels: true,
        zeroline: false,
      },
      yaxis: {
        visible: false,
        title: { text: "Season and Land Use", standoff: 100 },
        titlefont: { size: 24 },
        showticklabels: false,
        zeroline: false,
      },
      dragmode: false,
      hovermode: false,
      margin: { t: 0, b: 60, l: 60, r: 0, pad: 0 },
      colorway: [
        "#D04A0200",
        "#FFB60000",
        "#E0301E00",
        "#EB8C0000",
        "#D9395400",
        "#46464600",
        "#7D7D7D00",
        "#2d2d2d00",
      ],
    });

    if (firstSelection) {
      setFirstSelection({ label: t(firstSelection.value), value: firstSelection.value });
    }

    if (secondSelection) {
      setSecondSelection({ label: t(secondSelection.value), value: secondSelection.value });
    }

    setViolinLayout({
      height: 500,
      xaxis: {
        showticklabels: true,
        zeroline: false,
        tickmode: "array",
        tickvals: [0, 2, 4, 6],
        ticktext: [t("summer"), t("autumn"), t("winter"), t("spring")],
        tickfont: {
          size: 18,
        },
        showgrid: true,
      },
      yaxis: {
        showgrid: false,
        title: { text: t("temperature"), standoff: 100 },
        titlefont: { size: 24 },
        showticklabels: false,
        zeroline: false,
      },
      dragmode: false,
      hovermode: false,
      margin: { t: 0, b: 60, l: 0, r: 0, pad: 0 },
      colorway: colors.slice(0, 2),
    });

    if (temperatureSelection) {
      setTemperatureSelection({ label: t(temperatureSelection.value), value: temperatureSelection.value });
    }
  }

  const scrollFirstSection = () => {
    const firstElement = document.getElementById("first-section");
    firstElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  useEffect(() => {
    const video = document.getElementById("video-intro") as HTMLVideoElement;
    video.addEventListener('loadedmetadata', function () {
      setAnimate(true);
    })
  }, []);

  return (
    <div>
      <div className="header">
        <a href="https://www.pwc.com/sk/sk">
          <img className="logo" src={logo} alt="logo" />
        </a>

        <div>
          <Select
            value={languageSelect}
            defaultValue={languageOptions[0]}
            options={languageOptions}
            onChange={onClickLanguageChange}
            className="w-36" />
        </div>

      </div>

      <div className="introduction">
        <div className="bg-white rounded-2xl intro-text-container w-11/12 lg:w-2/3 xl:w-1/2 z-20">
          <div className="flex flex-col p-5 sm:p-6 md:p-7 lg:p-8 xl:p-9 h-full justify-between" style={{ minHeight: 'fit-content' }}>
            <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-5">
              {t('mainTitle1')}<br />{t('mainTitle2')}<span className="text-pink">{t('mainTitle3')}</span>{t('mainTitle4')}
            </div>

            <div className="relative">
              <div className="text-sm xl:text-base text-justify pb-5">
                {t('introText')}
              </div>
            </div>

            <div className="flex flex-row justify-between items-center">
              <button className="rounded-full bg-dark-gray p-3 w-32 text-white text-sm z-10" onClick={scrollFirstSection}>{t('learnMore')}</button>
            </div>
          </div>
        </div>
        <div>
          <img className="introduction-map" src={introMap} alt="intro map" />
          <video id="video-intro" className={animate ? "introduction-video animateIntro opacity-70" : "introduction-video opacity-0"} src={introVideo} autoPlay={true} muted={true} loop={true} height={500} />
        </div>

      </div>

      <div className="">
        <div className="container-center">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6 section mx-2" id="first-section">
            <div className="col-span-1 grid grid-rows-4 md:grid-rows-6">
              <div className="row-span-4 bg-pink rounded-2xl mt-6 text-white">
                <div className="flex flex-col h-full justify-between p-7 md:p-9">
                  <div className="items-center pb-7">
                    <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                      {t('clearPicture')}
                    </span>
                  </div>
                  <div className="text-sm text-justify pb-5">
                    {t('clearPictureText1')}
                  </div>
                  <div className="text-sm text-justify pb-5">
                    {t('clearPictureText2')}
                  </div>
                  <div className="text-sm text-justify">{t('clearPictureText3')}</div>
                  <div className="flex flex-row justify-end items-center hidden md:flex">
                    <ArrowRightIcon className="h-8 w-8 text-black-500" />
                  </div>
                </div>
              </div>

              <div className="row-span-2 bg-pink rounded-2xl mt-6 top-down-image hidden md:flex" />
            </div>

            <div className="col-span-1 flex flex-col">
              <div className="bg-gray rounded-2xl mt-6 text-white">
                <div className="flex flex-col p-7 md:p-9 h-full justify-between">
                  <div className="text-sm text-justify">
                    {t('clearPictureText4')}
                  </div>

                  <div className="flex flex-row justify-center my-3">
                    <button className="p-3 w-fit text-white text-sm bg-pink rounded-full" onClick={switchSelectedOverlay}>{t('switchOverlay')}</button>
                  </div>

                  <div className="relative">
                    <div className="text-sm text-justify" id="overlay-extra-placeholder" style={{ display: 'none', opacity: '0' }}>
                      {t('clearPictureText5')}
                    </div>
                    <div className="text-sm text-justify absolute top-0" id="overlay-extra-text" style={{ opacity: '0' }}>
                      {t('clearPictureText5')}
                    </div>
                  </div>

                  <div className="flex flex-row justify-end items-center">
                    <ArrowDownIcon className="h-8 w-8 text-black-500" />
                  </div>
                </div>
              </div>

              <div className="bg-pink rounded-2xl mt-6 temperature-container h-full">
                <Suspense fallback={<Loading />}>
                  <VideoLeaflet
                    center={BRATISLAVA_CENTER}
                    scrollWheelZoom={false}
                    zoom={12}
                    metricScale={false}
                    videoOverlay={{
                      videoURL: overlays[selectedOverlay],
                      videoCaptions: overlay_captions[selectedOverlay],
                      videoTemperatures: overlay_minmax[selectedOverlay],
                      videoCaptionLabelStyler: (label) => `${label[4]}${label[5]}. ${label[0]}${label[1]}${label[2]}${label[3]}`,
                      opacity: 0.8,
                      autoplay: true,
                      playsInLine: true,
                      alt: "h",
                      loop: true,
                      bounds: IMAGE_BOUNDS_BRATISLAVA,
                    }}
                  />
                </Suspense>
              </div>
            </div>
          </div>

          <div className="hidden md:grid grid-cols-2 gap-6 mt-6 section mx-2">
            <div className="col-span-1 flex flex-col">
              <div className="bg-pink rounded-2xl h-fit relative magnifier-container">
                <Suspense fallback={<Loading />}>
                  <Magnifierv2 imageSrc={Simg} imageAlt={"obrazek"} largeImageSrc={Limg} />
                  <CursorArrowRippleIcon className="h-32 w-32 text-black-500 cursor-icon" />
                </Suspense>
              </div>

              <div className="bg-white text-black border border-1 rounded-2xl mt-6 flex flex-col p-9 h-full">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-7">
                  {t('closerLookTitle2')}
                </div>
                <div className="pb-5 text-sm text-justify">
                  {t('closerLookText4')}
                </div>
                <div className="text-sm text-justify">
                  {t('closerLookText5')}
                </div>
                <div className="flex flex-row justify-end items-center hidden md:flex">
                  <ArrowDownIcon className="h-8 w-8 text-black-500" />
                </div>
              </div>
            </div>

            <div className="col-span-1 flex flex-col mt-0">
              <div className="bg-gray text-white rounded-2xl">
                <div className="flex flex-col p-7 md:p-9 h-full">
                  <div className="pb-4 flex flex-row justify-start items-center hidden md:flex">
                    <ArrowLeftIcon className="h-8 w-8 " />
                  </div>
                  <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-7">
                    {t('closerLookTitle1')}
                  </div>
                  <div className="pb-5 text-sm text-justify">
                    {t('closerLookText1')}
                  </div>
                  <div className="pb-5 text-sm text-justify">
                    {t('closerLookText2')}
                  </div>
                  <div className="text-sm text-justify">
                    {t('closerLookText3')}
                  </div>
                </div>
              </div>

              <div className="bg-pink rounded-2xl mt-6 h-full stock-image-water hidden lg:flex" />
            </div>
          </div>

          <div className="md:hidden grid grid-cols-1 mt-6 section mx-2">
            <div className="bg-gray text-white rounded-2xl">
              <div className="flex flex-col p-7 md:p-9 h-full">
                <div className="pb-4 flex flex-row justify-start items-center hidden md:flex">
                  <ArrowLeftIcon className="h-8 w-8 " />
                </div>
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-7">
                  {t('closerLookTitle1')}
                </div>
                <div className="pb-5 text-sm text-justify">
                  {t('closerLookText1')}
                </div>
                <div className="pb-5 text-sm text-justify">
                  {t('closerLookText2')}
                </div>
                <div className="text-sm text-justify">
                  {t('closerLookText3')}
                </div>
              </div>
            </div>

            <div className="bg-pink rounded-2xl h-fit relative magnifier-container mt-6">
              <Suspense fallback={<Loading />}>
                <Magnifierv2 imageSrc={Simg} imageAlt={"obrazek"} largeImageSrc={Limg} magnifierOffsetY={-120} />
                <CursorArrowRippleIcon className="h-32 w-32 text-black-500 cursor-icon" />
              </Suspense>
            </div>

            <div className="bg-white text-black border border-1 rounded-2xl mt-6 flex flex-col p-7">
              <div className="text-2xl pb-7">
                {t('closerLookTitle2')}
              </div>
              <div className="pb-5 text-sm text-justify">
                {t('closerLookText4')}
              </div>
              <div className="text-sm text-justify">
                {t('closerLookText5')}
              </div>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-1 mx-2 temperature-container">
            <div className="col-span-1 bg-gray rounded-2xl text-white">
              <Suspense fallback={<Loading />}>
                <VideoLeaflet
                  center={BRATISLAVA_CENTER}
                  scrollWheelZoom={false}
                  zoom={13}
                  metricScale={false}
                  videoOverlay={{
                    videoURL: localNormedL8,
                    videoCaptions: localNormedL8Captions,
                    videoTemperatures: localNormedL8MinMax,
                    opacity: 0.8,
                    controls: true,
                    autoplay: true,
                    playsInLine: true,
                    alt: "h",
                    loop: true,
                    bounds: IMAGE_BOUNDS_BRATISLAVA,
                  }}
                />
              </Suspense>
            </div>
          </div>

          <div className="mx-2 mt-6 grid grid-cols-5 gap-6 ">
            <div className="col-span-5 md:col-span-3 bg-gray rounded-2xl text-white p-7 md:p-9 flex flex-col gap-5">
              <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                {t('urbanHeatTitle')}
              </div>

              <div className="flex-col h-full justify-center">
                <div className="text-sm pb-5 text-justify">
                  {t('urbanHeatText1')}
                </div>
                <div className="text-sm text-justify">
                  <span>
                    <i>{t('urbanHeatText2')}</i>{t('urbanHeatText3')}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-span-5 md:col-span-2 bg-pink rounded-2xl h-full buildings-stock hidden md:flex" />
          </div>

          <div className="section-urban mx-2 mt-6">
            <div className="bg-gray rounded-2xl text-white temperature-cutoff">
              <Suspense fallback={<Loading />}>
                <TemperatureCutoff
                  zoom={12}
                  center={BRATISLAVA_CENTER}
                  bounds={IMAGE_BOUNDS_BRATISLAVA}
                  opacity={0.8}
                  alt={"Map showing heatspots with a slider that can be used to set the minimum and maximum boudaries for the spots."}
                />
              </Suspense>
            </div>

            <div className="bg-gray rounded-2xl text-white text-center text2">
              <div className="flex flex-col p-7 h-full justify-around">
                <div className="text-sm text-justify">
                  {t('urbanHeatText7')}
                </div>
                <div className="flex flex-row justify-end items-center hidden lg:flex">
                  <ArrowRightIcon className="h-8 w-8 text-black-500" />
                </div>
              </div>
            </div>

            <div className="bg-pink rounded-2xl text-white text1">
              <div className="flex flex-col p-7 md:p-9">
                <div className="text-sm text-justify pb-5">
                  {t('urbanHeatText4')}
                </div>

                <div className="text-sm text-justify pb-0 lg:pb-5">
                  {t('urbanHeatText6')}
                </div>

                <div className="flex flex-row justify-start items-center hidden lg:flex">
                  <ArrowLeftIcon className="h-8 w-8 text-black-500" />
                </div>
              </div>
            </div>

            <div className="bg-yellow rounded-2xl child-h-full child-rounded-2xl img-split">
              <Suspense fallback={<Loading />}>
                <ImgSplitPane NDVIimg={HeatScoreImg} imgL={imgL} />
              </Suspense>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-5 md:gap-6 section-heatspots mx-2 grid-rows-1">
            <div className="bg-pink col-span-2 row-span-1 rounded-2xl mt-6 md:hidden flex flex-col p-7 md:p-9 text-white">
              <div className="text-sm text-justify pb-5">
                {t('urbanHeatText8')}
              </div>

              <div className="text-sm text-justify">
                {t('urbanHeatText9')}
              </div>

              <ul className="pb-5 features-list">
                <li>{t('urbanHeatText10')}</li>
                <li>{t('urbanHeatText11')}</li>
              </ul>

              <div className="text-sm text-justify pb-0 md:pb-5">
                {t('urbanHeatText12')}
              </div>
              <div className="flex flex-row justify-start items-center hidden md:flex">
                <ArrowLeftIcon className="h-8 w-8 text-black-500" />
              </div>
            </div>

            <div className="row-span-1 col-span-3 rounded-2xl mt-6 child-h-full child-rounded-2xl spot-info">
              <Suspense fallback={<Loading />}>
                <SpotInfo
                  zoom={12}
                  center={BRATISLAVA_CENTER}
                  imageOverlay={[
                    {
                      bounds: IMAGE_BOUNDS_BRATISLAVA,
                      imageURL: hetspot_bwr_img,
                      opacity: 0.7,
                      alt: "",
                    },
                  ]}
                />
              </Suspense>
            </div>

            <div className="bg-pink col-span-2 row-span-1 rounded-2xl mt-6 hidden md:flex flex-col p-7 md:p-9 text-white">
              <div className="text-sm text-justify pb-5">
                {t('urbanHeatText8')}
              </div>

              <div className="text-sm text-justify">
                {t('urbanHeatText9')}
              </div>

              <ul className="pb-5 features-list">
                <li>{t('urbanHeatText10')}</li>
                <li>{t('urbanHeatText11')}</li>
              </ul>

              <div className="text-sm text-justify pb-0 md:pb-5">
                {t('urbanHeatText12')}
              </div>
              <div className="flex flex-row justify-start items-center hidden md:flex">
                <ArrowLeftIcon className="h-8 w-8 text-black-500" />
              </div>
            </div>
          </div>

          <div className="mx-2 grid grid-cols-10 md:grid-cols-9 lg:grid-cols-10 bg-gray rounded-2xl mt-6 text-white p-7 md:p-9 gap-6">
            <div className="items-center col-span-10 md:col-span-4 lg:col-span-4 flex justify-center">
              <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                {t('whatCausesTitle1')} <br className="hidden md:flex" />{t('whatCausesTitle2')}
              </span>
            </div>
            <div className="flex flex-col col-span-10 md:col-span-5 lg:col-span-6 h-full justify-evenly">
              <div className="text-sm pb-5 text-justify">
                {t('whatCausesText1')}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 section mx-2 mt-6">
            <div className="col-span-1 bg-white p-7 md:p-9 rounded-2xl border">
              <div className="flex flex-col gap-6 items-center">
                <div className="w-40">
                  <img src={infrastructure} />
                </div>
                <span className="text-xl font-bold">
                  {t('surfacesTitle')}
                </span>
                <span className="text-sm text-justify">
                  {t('surfacesText')}
                </span>
              </div>
            </div>

            <div className="col-span-1 bg-white p-7 md:p-9 rounded-2xl border">
              <div className="flex flex-col gap-6 items-center">
                <div className="w-40">
                  <img src={agriculture} />
                </div>
                <span className="text-xl font-bold">
                  {t('vegetationTitle')}
                </span>
                <span className="text-sm text-justify">
                  {t('vegetationText')}
                </span>
              </div>
            </div>

            <div className="col-span-1 bg-white p-7 md:p-9 rounded-2xl border">
              <div className="flex flex-col gap-6 items-center">
                <div className="w-40">
                  <img src={community} />
                </div>
                <span className="text-xl font-bold">
                  {t('humanTitle')}
                </span>
                <span className="text-sm text-justify">
                  {t('humanText')}
                </span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-6 mx-2 mt-6">
            <div className="col-span-1 flex flex-col gap-6">
              <div className="bg-pink p-7 md:p-9 rounded-2xl h-full">
                <div className="text-sm text-justify text-white pb-5">
                  {t('ridgeText1')}
                </div>

                <div className="text-sm text-justify text-white pb-0 lg:pb-5">
                  {t('ridgeText2')}
                </div>

                <div className="flex flex-row justify-end items-center hidden lg:flex">
                  <ArrowRightIcon className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="bg-pink rounded-2xl h-full field-stock hidden xl:flex" />
            </div>

            <div className="col-span-2 rounded-2xl outline outline-1 overflow-hidden mt-6 lg:mt-0">
              <Suspense fallback={<Loading />}>
                <RidgelineControls
                  formattedData={formattedData}
                  filteredData={filteredData}
                  selectedSeason={selectedSeason}
                  setSelectedSeason={setSelectedSeason}
                  selectedLandUses={selectedLandUses}
                  setSelectedLandUses={setSelectedLandUses}
                  layout={layout}
                  setLayout={setLayout}
                />
              </Suspense>
            </div>
          </div>

          <div className="violin-container mx-2 mt-6">
            <div className="violin-plot rounded-2xl outline outline-1 overflow-hidden">
              <Suspense fallback={<Loading />}>
                <ViolinPlotContainer
                  formattedData={formattedData}
                  availableLandUses={availableLandUses}
                  firstSelection={firstSelection}
                  setFirstSelection={setFirstSelection}
                  secondSelection={secondSelection}
                  setSecondSelection={setSecondSelection}
                  violinLayout={violinLayout}
                  setViolinLayout={setViolinLayout}
                />
              </Suspense>
            </div>

            <div className="violin-text flex flex-col gap-6">
              <div className="bg-pink p-7 md:p-9 rounded-2xl h-full">
                <div className="text-sm text-justify text-white pb-5">
                  {t('violinText1')}
                </div>

                <div className="text-sm text-justify text-white pb-0 lg:pb-5">
                  {t('violinText2')}
                </div>

                <div className="flex flex-row justify-start items-center hidden lg:flex">
                  <ArrowLeftIcon className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="bg-pink rounded-2xl h-full city-stock hidden xl:flex" />
            </div>
          </div>

          <div className="mx-2 mt-6">
            <div className="grid grid-rows-1 bg-gray rounded-2xl text-white">
              <div className="grid grid-cols-5 justify-evenly p-7 md:p-9">
                <div className="col-span-5 md:col-span-2 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-7 md:pb-0 md:pr-6 flex items-center">
                  {t('vegetationGreatTitle')}
                </div>
                <div className="col-span-5 md:col-span-3 flex flex-col h-full justify-evenly">
                  <div className="text-sm pb-5 text-justify">
                    {t('vegetationGreatText1')}
                  </div>
                  <div className="text-sm md:text-justify">
                    {t('vegetationGreatText2')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-rows-1 mx-2 grid grid-cols-2 mt-6 gap-6">
            <div className="row-span-1 col-span-2 md:col-span-1 rounded-2xl child-h-full child-rounded-2xl split-view">
              <Suspense fallback={<Loading />}>
                <ImgSplitPane NDVIimg={NDVIimg} imgL={imgL} />
              </Suspense>
            </div>

            <div className="row-span-1 col-span-2 md:col-span-1 rounded-2xl child-h-full child-rounded-2xl border border-1 overflow-hidden scatter-plot">
              <Suspense fallback={<Loading />}>
                <Scatterplot data={parsecsv} width={820} height={550} />
              </Suspense>
            </div>
          </div>

          <div className="mx-2 grid grid-cols-10 bg-gray rounded-2xl mt-6 text-white p-7 md:p-9 gap-6">
            <div className="items-center col-span-10 md:col-span-4 flex justify-center text-start">
              <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                {t('whyThisMatterTitle')}
              </span>
            </div>

            <div className="flex flex-col col-span-10 md:col-span-6 h-full justify-evenly">
              <div className="text-sm pb-5 text-justify">
                {t('whyThisMatterText1')} {" "}
                <a className="underline" href="https://en.wikipedia.org/wiki/1995_Chicago_heat_wave" >
                  <span>{t('whyThisMatterText2')}</span>
                </a>{" "} {t('whyThisMatterText3')}
              </div>
              <div className="text-sm text-justify">
                {t('whyThisMatterText4')}<sub>2</sub>{t('whyThisMatterText5')}
                <Suspense fallback={<div />}>
                  <ReadMore initialText={t('readMoreTitle')}>
                    <div className="SectionHeader">
                      <h3 className="font-semibold pb-2">{t('readMoreTitle1')}</h3>
                    </div>
                    <p className="pb-4 text-sm">
                      {t('readMoreText1')}
                    </p>
                    <p className="pb-4 text-sm">
                      {t('readMoreText2')}
                    </p>
                    <p className="pb-4 text-sm">
                      {t('readMoreText3')}
                    </p>
                    <p className="pb-6 text-sm">
                      {t('readMoreText4')}
                    </p>
                    <div className="SectionHeader">
                      <h3 className="font-semibold pb-2">{t('readMoreTitle2')}</h3>
                    </div>
                    <p className="pb-4 text-sm">
                      {t('readMoreText5')}
                    </p>
                    <p className="pb-4 text-sm">
                      {t('readMoreText6')}
                    </p>
                    <p className="pb-4 text-sm">
                      {t('readMoreText7')}
                    </p>
                    <p className="pb-6 text-sm">
                      {t('readMoreText8')}<sub>2</sub>{t('readMoreText9')}<sub>2</sub>{t('readMoreText10')}<sub>2</sub>{t('readMoreText11')}
                    </p>
                    <div className="SectionHeader">
                      <h3 className="font-semibold pb-2">{t('readMoreTitle3')}</h3>
                    </div>
                    <p className="text-sm">
                      [0] Piracha, A.; Chaudhary, M.T. Urban Air Pollution, Urban Heat
                      Island and Human Health: A Review of the Literature.
                      Sustainability 2022, 14, 9234.{" "}
                      <a href="https://doi.org/10.3390/su14159234">
                        [https://doi.org/10.3390/su14159234]
                      </a>
                    </p>
                    <p className="text-sm">
                      [1] Tuholske, C.; Caylor, K.; Funk, C.; Verdin, A.; Sweeney, S.;
                      Grace, K.; Peterson, P.; Evans, T. Global urban population
                      exposure to extreme heat. Proc. Natl. Acad. Sci. USA 2021, 118,
                      e2024792118.{" "}
                      <a href="https://scholar.google.com/scholar_lookup?title=Global+urban+population+exposure+to+extreme+heat&author=Tuholske,+C.&author=Caylor,+K.&author=Funk,+C.&author=Verdin,+A.&author=Sweeney,+S.&author=Grace,+K.&author=Peterson,+P.&author=Evans,+T.&publication_year=2021&journal=Proc.+Natl.+Acad.+Sci.+USA&volume=118&pages=e2024792118&doi=10.1073/pnas.2024792118">
                        [Google Scholar]
                      </a>
                    </p>
                    <p className="text-sm">
                      [2] Crandall, C.G.; Gonzalez-Alonso, J. Cardiovascular function in
                      the heat-stressed human. Acta Physiol. 2010, 199, 407–423.{" "}
                      <a href="https://scholar.google.com/scholar_lookup?title=Cardiovascular+function+in+the+heat-stressed+human&author=Crandall,+C.G.&author=Gonzalez-Alonso,+J.&publication_year=2010&journal=Acta+Physiol.&volume=199&pages=407%E2%80%93423&doi=10.1111/j.1748-1716.2010.02119.x&pmid=20345414">
                        [Google scholar]
                      </a>
                    </p>
                    <p className="text-sm">
                      [3] Baccini, M.; Biggeri, A.; Accetta, G.; Kosatsky, T.;
                      Katsouyanni, K.; Analitis, A.; Anderson, H.R.; Bisanti, L.;
                      D’Ippoliti, D.; Danova, J.; et al. Heat effects on mortality in 15
                      European cities. Epidemiology 2008, 19, 711–719.{" "}
                      <a href="https://scholar.google.com/scholar_lookup?title=Heat+effects+on+mortality+in+15+European+cities&author=Baccini,+M.&author=Biggeri,+A.&author=Accetta,+G.&author=Kosatsky,+T.&author=Katsouyanni,+K.&author=Analitis,+A.&author=Anderson,+H.R.&author=Bisanti,+L.&author=D%E2%80%99Ippoliti,+D.&author=Danova,+J.&publication_year=2008&journal=Epidemiology&volume=19&pages=711%E2%80%93719&doi=10.1097/EDE.0b013e318176bfcd&pmid=18552587">
                        [Google Scholar]
                      </a>
                    </p>
                    <p className="text-sm">
                      [4] Historical climate data for Bratislava{" "}
                      <a href="https://www.meteoblue.com/en/weather/historyclimate/climatemodelled/bratislava_slovakia_3060972">
                        [Meteoblue]
                      </a>
                    </p>
                    <p className="text-sm">
                      [5] Mortality rates in Slovak republic{" "}
                      <a href="https://www7.statistics.sk/wps/portal/ext/products/informationmessages/inf_sprava_detail/!ut/p/z1/rVJLj9MwEP4tHHJ0PImdOuGWrqDtUpCWqrT1BdmJ03qT2NnETVl-PU5BSCvRRUj4Yo_noe8xmOM95kaM-iictkY0Pj7w2dcHtkrn8ygHmK8JrO4_bD4t797Hi22Cv2COeWFc5074YOUgTkgZpE2FRO0C8A_bt37aaBQaul6MzwGMg3K1vwVVmZQiRkDLClEaZygrq8KHEhiJBEshm8Z3hS7xoRAJyDKKkALhqzNFkIyAoIRSBQBlSRKJd3_Dy30abpwc8EYZfI-5lm14KdoQQhZHJCNZDNEMYMLjFdGPT08897Stceqbw_ufvIc6gMF5rgW6Zozn71TridpyVG60o0DX_OB0LX5rU2iFatuejf8stEACdXZwdvR6Nefv0re3yolzKR6Vp8evDO4W-ZKytUe0XiSwypfbz9kDIZCTXwWvUDx4idhNiSKGd6NWF7w1E7gGb_7RgSVMAr5uwm0N_-_uvHQyZUAYS2lMs1lCyIQiN5KkR8x7Vale9eG59yt_cq4b3gYQwOVyCY_WHhsVFrYN4E8tJ28W3r-sxF3bpuQZLXaorj6-I5TLpBnX-Zsf60Ntrg!!/dz/d5/L2dBISEvZ0FBIS9nQSEh/">
                        [Statistical Office of the Slovak Republic]
                      </a>
                    </p>
                    <p className="text-sm">
                      [6] Colelli, F.P., Wing, I.S. & Cian, E.D. Air-conditioning
                      adoption and electricity demand highlight climate change
                      mitigation–adaptation tradeoffs. Sci Rep 13, 4413 (2023).{" "}
                      <a href="https://doi.org/10.1038/s41598-023-31469-z">
                        [Scientific reports]
                      </a>
                    </p>
                    <p className="text-sm">
                      [7] Average Slovak Household does not have Air Conditioning{" "}
                      <a href="https://spectator.sme.sk/c/20229015/average-slovak-does-not-have-air-conditioning.html">
                        [Slovak Spectator]
                      </a>
                    </p>
                    <p className="text-sm">
                      [8] Commercial and industrial space per capita in UK{" "}
                      <a href="https://www.researchgate.net/figure/Commercial-and-industrial-floor-space-per-capita-and-sq-km-of-area_tbl2_257425576">
                        [Researchgate]
                      </a>
                    </p>
                    <p className="text-sm">
                      [9] Commercial AC usage{" "}
                      <a href="https://www.iotacommunications.com/blog/benchmarking-commercial-building-energy-use-per-square-foot/">
                        [Blog]
                      </a>
                    </p>
                  </ReadMore>
                </Suspense>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 mx-2 mt-6">
            <Suspense fallback={<Loading />}>
              <ImpactSlider />
            </Suspense>
          </div>

          <div className="grid grid-cols-1 grid-rows-1 md:grid-cols-5 mx-2 mt-6 gap-6">
            <div className="col-span-1 md:col-span-2 flex flex-col">
              <div className="p-5 xl:p-8 items-center rounded-2xl bg-gray text-white justify-center district-title">
                <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-7">
                  {t('coolestDistrictTitle')}
                </div>

                <div className="text-sm text-justify pb-0 md:pb-5">
                  {t('coolestDistrictText')}
                </div>

                <div className="flex flex-row justify-end items-center hidden md:flex">
                  <ArrowRightIcon className="h-8 w-8 text-white" />
                </div>
              </div>

              <div className="bg-pink rounded-2xl h-full forest-road-stock hidden md:flex mt-6" />
            </div>

            <div className="col-span-1 md:col-span-3 flex flex-col">
              <div className="rounded-2xl overflow-hidden border border-1">
                <Suspense fallback={<Loading />}>
                  <BarPlotTempQuantilesContainer temperatureSelection={temperatureSelection} setTemperatureSelection={setTemperatureSelection} />
                </Suspense>
              </div>

              <div className="bg-pink rounded-2xl overflow-hidden mt-6 district-map">
                <Suspense fallback={<Loading />}>
                  <DistrictInfo
                    imageOverlay={[
                      {
                        bounds: IMAGE_BOUNDS_BRATISLAVA,
                        imageURL: TempQuantiles,
                        opacity: 0.7,
                        alt: "",
                      },
                    ]}
                  />
                </Suspense>
              </div>
            </div>
          </div>

          <div className="mt-6 mx-2">
            <div className="bg-pink rounded-2xl text-white">
              <div className="grid grid-cols-2 justify-evenly p-7 md:p-9">
                <div className="flex col-span-2 md:col-span-1 side-header items-center pb-5 md:pb-0 text-start justify-center">
                  <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                    {t('minigameTitle')}
                  </span>
                </div>

                <div className="col-span-2 md:col-span-1 h-full flex items-center">
                  <div className="text-sm text-justify">
                    {t('minigameText')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section mx-2 mt-6 child-h-full minigame-container">
            <Suspense fallback={<Loading />}>
              <Minigame
                zoom={[11, 11, 11, 11, 11]}
                scrollWheelZoom={true}
                metricScale={false}
                imageOverlay={imageOverlayArray}
              />
            </Suspense>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-2 gap-6 mx-2 mt-6">
            <div className="col-span-1 row-span-2 bg-gray rounded-2xl text-white p-7 md:p-9">
              <div className="col-span-1 side-header items-center pb-7">
                <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                  {t('solutionTitle')}
                </span>
              </div>

              <div className="h-full">
                <div className="text-sm pb-6 text-justify">
                  {t('solutionText1')}
                </div>

                <div className="text-sm text-justify pb-6">
                  {t('solutionText2')}
                </div>
                <div className="text-sm text-justify">
                  {t('solutionText3')}
                </div>
              </div>
            </div>

            <div className="col-span-1 row-span-1 bg-slate-600 overflow-hidden rounded-2xl text-white p-9 flex flex-col text-sm forest">
              <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-6 relative text-solution-title">
                {t('treesTitle')}
              </span>
              <span className="text-justify relative text-solution-info">
                {t('treesText')}
              </span>
            </div>

            <div className="col-span-1 row-span-1 bg-slate-600 overflow-hidden rounded-2xl text-white p-9 flex flex-col text-sm green-roofs">
              <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-6 relative text-solution-title">
                {t('roofsTitle')}
              </span>
              <span className="text-justify relative text-solution-info">
                {t('roofsText')}
              </span>
            </div>

            <div className="col-span-1 row-span-1 bg-slate-600 overflow-hidden rounded-2xl text-white p-9 flex flex-col text-sm water">
              <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-6 relative text-solution-title">
                {t('waterTitle')}
              </span>
              <span className="text-justify relative text-solution-info">
                {t('waterText')}
              </span>
            </div>

            <div className="col-span-1 row-span-1 bg-slate-600 overflow-hidden rounded-2xl text-white p-9 flex flex-col text-sm light-surfaces">
              <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl pb-6 relative text-solution-title">
                {t('coloredTitle')}
              </span>
              <span className="text-justify relative text-solution-info">
                {t('coloredText')}
              </span>
            </div>
          </div>

          <div className="flex justify-center mt-6">
            <div className="bg-gray text-white rounded-2xl w-fit p-3">
              {t('jobText')}{" "}<a target="_blank" rel="noopener noreferrer" href="https://www.pwc.com/sk/sk/kariera/technology-consulting.html" style={{ fontWeight: 'bold' }}>{t('hereClick')}</a>.
            </div>
          </div>
        </div>

        <div className='mt-6'>
          <Suspense fallback={<Loading />}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </div>

  );
};

export default App;
