import "./Loading.scss";

const Loading = () => {
    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loading;
